import React from 'react'
import { StackProps, YStack } from 'tamagui'

import ResponsiveXStack from './ResponsiveXStack'

export type SectionGridLayoutProps = {
  desktopGridTemplateColumns: string
} & StackProps

const SectionGridLayout = ({
  desktopGridTemplateColumns,
  children,
  ...stackProps
}: SectionGridLayoutProps) => {
  return (
    <ResponsiveXStack
      {...stackProps}
      mobile={
        <YStack width="100%" backgroundColor="$hairline" gap="1px" paddingTop="1px">
          {children}
        </YStack>
      }
      desktop={
        <YStack
          backgroundColor="$hairline"
          gap="1px"
          paddingTop="1px"
          width="100%"
          style={{
            display: 'grid',
            gridTemplateColumns: desktopGridTemplateColumns,
          }}
        >
          {children}
        </YStack>
      }
    />
  )
}

export default SectionGridLayout
